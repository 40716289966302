import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Predicate,
  TemplateRef,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { convertToFlattenTree } from '@core/misc/misc.utils';
import { ValidationMessage } from '@core/misc/validation.util';
import { RequestStateModel } from '@core/store/store.models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KzInputDirective } from '@shared/directives/kz-input.directive';
import { LodashGetPipe } from '@shared/pipes/lodash-get.pipe';
import { OrNullPipe } from '@shared/pipes/or-null.pipe';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-kz-desktop-multi-select-box',
  standalone: true,
  imports: [
    CommonModule,
    KzInputDirective,
    LodashGetPipe,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    OrNullPipe,
    ScrollingModule
  ],
  templateUrl: './kz-desktop-multi-select-box.component.html',
  styleUrl: './kz-desktop-multi-select-box.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KzDesktopMultiSelectBoxComponent)
    }
  ]
})
export class KzDesktopMultiSelectBoxComponent<T = unknown> implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() typedToken!: T;

  @Input() public inputId?: string;
  @Input() public title?: string;
  @Input() public optionLabel: keyof T = 'label' as keyof T;
  @Input() public optionValue: keyof T = 'value' as keyof T;
  @Input() public optionDisabled: keyof T = 'disabled' as keyof T;
  @Input() public showAllSelector = false;
  @Input() public showAnySelector = false;
  @Input() public loading = false;
  @Input() public errorMessage?: string | ValidationErrors | null;
  @Input() public optionSearchField?: keyof T;
  @Input() public optionParentField?: keyof T;
  @Input() public optionChildrenField?: keyof T;
  @Input() public virtualScrollItemSize = 40;
  @Input() public returnOptionsAsValue?: boolean;

  @Input() public options$?: Observable<RequestStateModel<T[]>>;
  @Input() public onLazyOptionLoad?: (d: T[] | null) => T[];
  @ContentChild('templateOptionLabel') public templateOptionLabel?: TemplateRef<unknown>;

  get options() {
    if (this.searchValue || this.showSelected || this._optionFilterPredicate) return this._filteredOptions;
    return this._options;
  }
  @Input() set options(options: T[]) {
    if (this._options.length) this.value = null;
    this.setOptions(options);
  }
  private _optionFilterPredicate: Predicate<T> | null = null;
  @Input() set optionFilterPredicate(predicate: Predicate<T> | null) {
    this._optionFilterPredicate = predicate;
    this.searchValue = '';
    if (this._optionFilterPredicate) {
      this._filteredOptions = this._options.filter(this._optionFilterPredicate);
    }
  }
  private _options: T[] = [];
  private _filteredOptions: T[] = [];

  public selected: Record<string, boolean> = {};
  public indeterminateSelects: Record<string, boolean> = {};
  @Input() set value(value: unknown[] | null) {
    if (value !== null && value.length) {
      const updatedParents: string[] = [];
      if (this.returnOptionsAsValue)
        for (let index = 0; index < value.length; index++) {
          if ((value as T[])[index][this.optionValue]) {
            this.selected[(value as T[])[index][this.optionValue] as string | number] = true;
            if (this.optionParentField) {
              const parentValue = (value as T[])[index][this.optionParentField] as string | undefined;
              if (parentValue && !updatedParents.includes(parentValue)) this.updateParentState(parentValue);
            }
          }
        }
      else for (let index = 0; index < value.length; index++) this.selected[value[index] as string | number] = true;
    } else this.selected = {};
  }
  public get value() {
    if (!this.selected) return null;
    if (this.returnOptionsAsValue) {
      return this.onlySelectedOptions;
    }

    const keys = Object.keys(this.selected);
    if (!keys.length) return null;
    const result: unknown[] = [];
    for (let index = 0; index < keys.length; index++) if (this.selected[keys[index]] === true) result.push(keys[index]);
    return result;
  }
  @Output() readonly selectedOptions = new EventEmitter<T[] | null>();

  private touched = false;
  public disabled = false;

  public get isAllSelected() {
    if (!this.options?.length) return false;
    return Object.values(this.selected).filter((e) => e === true).length === this._options.length;
  }

  public get isSomeSelected() {
    return Object.values(this.selected).includes(true);
  }
  public showSelected = false;
  private get onlySelectedOptions() {
    if (this._options === null) return [];
    return this._options.filter(
      (e) => e?.[this.optionValue as keyof T] && this.selected?.[e[this.optionValue as keyof T] as string] === true
    );
  }
  public searchValue = '';
  public get errorMessageText() {
    if (!this.errorMessage) return;
    const errorText = typeof this.errorMessage === 'object' ? ValidationMessage(this.errorMessage) : this.errorMessage;
    if (!errorText) return;
    return typeof errorText === 'object'
      ? this.translateService.instant(errorText.text, errorText.data)
      : this.translateService.instant(errorText);
  }

  private _destroySubscriptions$ = new Subject<void>();

  optionLevel = (item: T): number => {
    if (this.optionParentField && item?.[this.optionParentField]) {
      const findParent = (itemToCheck: T, level = 0): number => {
        const parentValue = itemToCheck?.[this.optionParentField as keyof T];
        if (!parentValue) return level;
        const parent = this.options?.find((e) => e?.[this.optionValue] === parentValue);
        if (parent) return findParent(parent, level + 1);
        return level;
      };
      return findParent(item);
    }
    return 0;
  };
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.runOptionsListener();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  private setOptions(options: T[]) {
    this._options = this.optionChildrenField
      ? (convertToFlattenTree(options, this.optionChildrenField, this.optionValue) ?? [])
      : (this._options = options ?? []);

    this.searchValue = '';
  }
  private destroySubscriptions() {
    if (this._destroySubscriptions$?.next) {
      this._destroySubscriptions$.next();
      this._destroySubscriptions$.complete();
    }
  }
  public runOptionsListener() {
    if (this.options$) {
      this.options$.pipe(takeUntil(this._destroySubscriptions$)).subscribe((r) => {
        if (r.loadState.status === 'loading') this.loading = true;
        else if (this.loading) this.loading = false;
        if (r.loadState.status === 'completed') {
          this.setOptions(this.onLazyOptionLoad ? this.onLazyOptionLoad(r.response) : (r.response as T[]));
        }
      });
    }
  }
  public toggleAny(state: boolean) {
    if (state === true) {
      this.value = null;
      this.indeterminateSelects = {};
      if (this.showSelected) this.showSelected = false;
      this.applyChanges();
    }
  }
  public toggleAll(state: boolean) {
    if (!this.options?.length) return;
    if (this.isAllSelected && this.showSelected) this.showSelected = false;
    if (this.searchValue) this.searchValue = '';
    this.value = null;
    this.options.forEach((e) => (this.selected[e[this.optionValue as keyof T] as string] = state));
    this.applyChanges();
  }
  private applyChanges() {
    //if (selectedValue !== undefined && this.optionParentField) this.toggleChilds(selectedValue, state);
    this.markAsTouched();
    this.selectedOptions.emit(this.onlySelectedOptions);
    this.onChangeCallback(this.value);
    if (this.showSelected) if (!this.isSomeSelected) this.showSelected = false;
  }

  public toggleCheckbox(parent: string, selectedValue: unknown, state: boolean) {
    if (this.optionParentField) {
      if (!parent) {
        this._options
          .filter((e) => e?.[this.optionParentField as keyof T] === selectedValue)
          .forEach((e) => {
            this.selected[e[this.optionValue] as string] = state;
          });
        if (this.indeterminateSelects?.[selectedValue as string] && state === false)
          this.indeterminateSelects[selectedValue as string] = false;
      } else this.updateParentState(parent);
    }
    this.applyChanges();
  }

  private updateParentState(parent: string) {
    let selectedCount = 0;
    const toSelectCount = this._options.filter((e) => {
      if (e?.[this.optionParentField as keyof T] === parent) {
        if (this.selected[e[this.optionValue] as string]) selectedCount++;
        return true;
      }
      return false;
    }).length;
    if (selectedCount) this.indeterminateSelects[parent] = true;
    else this.indeterminateSelects[parent] = false;

    if (toSelectCount === selectedCount) this.selected[parent] = true;
    else this.selected[parent] = false;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouchedCallback();
      this.touched = true;
    }
  }

  public filterOnlySelected() {
    if (!this.showSelected) {
      if (this._optionFilterPredicate) {
        this._filteredOptions = this._options.filter(this._optionFilterPredicate);
      } else {
        this._filteredOptions = [];
      }
      return;
    }
    this.searchValue = '';
    const valuesToShow: string[] = [];
    const getAllParents = (value: string, previousValues: string[] = []): string[] => {
      const parent = this._options.find((e) => e[this.optionValue] === value);
      if (parent?.[this.optionParentField as keyof T]) {
        return getAllParents(parent[this.optionParentField as keyof T] as string, [value, ...previousValues]);
      } else return [value, ...previousValues];
    };
    for (let index = 0; index < this._options.length; index++) {
      if (
        this._options[index]?.[this.optionValue as keyof T] &&
        this.selected?.[this._options[index][this.optionValue as keyof T] as string] === true
      ) {
        if (this._options[index]?.[this.optionValue]) {
          valuesToShow.push(this._options[index][this.optionValue] as string);
          if (this.optionParentField && this._options[index]?.[this.optionParentField]) {
            const parentValues = getAllParents(this._options[index][this.optionParentField] as string);
            if (parentValues.length) valuesToShow.push(...parentValues);
          }
        }
      }
    }

    this._filteredOptions = this._options.filter((option) => valuesToShow.includes(option[this.optionValue] as string));
  }
  public onSearch() {
    const options = this._optionFilterPredicate ? this._options.filter(this._optionFilterPredicate) : this._options;
    if (!this.searchValue) {
      this._filteredOptions = options;
      return;
    }
    const searchValue = this.searchValue.toLowerCase();
    const valuesToShow: string[] = [];
    const getAllParents = (value: string, previousValues: string[] = []): string[] => {
      const parent = this._options.find((e) => e[this.optionValue] === value);
      if (parent?.[this.optionParentField as keyof T]) {
        return getAllParents(parent[this.optionParentField as keyof T] as string, [value, ...previousValues]);
      } else return [value, ...previousValues];
    };
    const searchField = this.optionSearchField ?? this.optionLabel;
    for (let index = 0; index < this._options.length; index++) {
      if ((this._options?.[index]?.[searchField] as string)?.toLowerCase().includes(searchValue)) {
        if (this._options[index]?.[this.optionValue]) {
          valuesToShow.push(this._options[index][this.optionValue] as string);
          if (this.optionParentField && this._options[index]?.[this.optionParentField]) {
            const parentValues = getAllParents(this._options[index][this.optionParentField] as string);
            if (parentValues.length) valuesToShow.push(...parentValues);
          }
        }
      }
    }

    this._filteredOptions = options.filter((option) => valuesToShow.includes(option[this.optionValue] as string));
    if (!this.optionParentField)
      this._filteredOptions = this._filteredOptions.sort((a, b) => {
        const aValue = (a[searchField] as string).toLowerCase();
        const bValue = (b[searchField] as string).toLowerCase();
        const aStarts = aValue.startsWith(searchValue);
        const bStarts = bValue.startsWith(searchValue);

        if (aStarts && bStarts) return aValue.localeCompare(bValue);
        if (aStarts && !bStarts) return -1;
        if (!aStarts && bStarts) return 1;
        return aValue.localeCompare(bValue);
      });
  }
  /* Accessors requistments */
  /* eslint-disable @typescript-eslint/no-empty-function */
  private onTouchedCallback: () => unknown = () => {};
  private onChangeCallback: (value: unknown[] | null) => void = () => {};

  public writeValue(value: unknown[]) {
    this.value = value;
  }

  public registerOnChange(onChange: (value: unknown[] | null) => unknown) {
    this.onChangeCallback = onChange;
  }
  public registerOnTouched(onTouched: () => unknown) {
    this.onTouchedCallback = onTouched;
  }
  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}

<div class="w-full flex flex-col">
  <div class="flex flex-row pb-1 items-center justify-between">
    <label [for]="inputId + '_search-box'">{{ title ? (title | translate) : '' }}</label>
    <div class="flex justify-end gap-2">
      @if (showAllSelector) {
        <mat-checkbox class="no-left" color="primary" [checked]="isAllSelected" (change)="toggleAll($event.checked)">{{
          'Check All' | translate
        }}</mat-checkbox>
      }
      @if (showAnySelector) {
        <mat-checkbox
          class="no-left readonly-checkbox"
          color="primary"
          [checked]="!isSomeSelected"
          [disabled]="!isSomeSelected"
          (change)="toggleAny($event.checked)"
          >{{ 'Any' | translate }}</mat-checkbox
        >
      }
      <mat-checkbox
        class="no-left"
        color="primary"
        [disabled]="isAllSelected || !isSomeSelected || !options.length"
        [(ngModel)]="showSelected"
        (ngModelChange)="filterOnlySelected()"
        >{{ 'Show Only Selected' | translate }}</mat-checkbox
      >
    </div>
  </div>
  <div
    class="w-full border-[1px] border-kz-light-blue border-solid"
    [ngClass]="{ 'border-kz-danger': errorMessage ? true : false }"
  >
    <input
      kzInput
      icon="search-2"
      placeholder="Type to Filter"
      autocomplete="off"
      styleClass="search-input"
      [id]="inputId + '_search-box'"
      [(ngModel)]="searchValue"
      (ngModelChange)="onSearch()"
    />
    <select class="hidden" multiple [value]="value" [id]="inputId"></select>
    @if (!loading) {
      <div class="h-[300px]">
        @if (options.length) {
          <cdk-virtual-scroll-viewport style="height: 100%" [itemSize]="virtualScrollItemSize">
            <div class="flex flex-col">
              <ng-container *cdkVirtualFor="let option of options; index as index">
                @if (option | lodashGet: [optionValue, optionLabel, optionDisabled, optionParentField]; as data) {
                  <mat-checkbox
                    class="multi-select-checkbox"
                    color="primary"
                    [ngClass]="optionParentField ? { 'ml-[2rem!important]': data?.[optionParentField] } : {}"
                    [disabled]="disabled || data[optionDisabled]"
                    [indeterminate]="
                      !data?.[optionParentField] && !selected[data[optionValue]]
                        ? indeterminateSelects[data[optionValue]]
                        : false
                    "
                    [value]="data[optionValue]"
                    [(ngModel)]="selected[data[optionValue]]"
                    (ngModelChange)="toggleCheckbox(data?.[optionParentField], data[optionValue], $event)"
                  >
                    @if (templateOptionLabel) {
                      <ng-container
                        [ngTemplateOutlet]="templateOptionLabel"
                        [ngTemplateOutletContext]="{ $implicit: option }"
                      />
                    } @else {
                      {{ data[optionLabel] | translate }}
                    }
                  </mat-checkbox>
                }
              </ng-container>
            </div>
          </cdk-virtual-scroll-viewport>
        } @else {
          <div class="p-2 text-center italic text-kz-grey-400">{{ 'No data to select' | translate }}</div>
        }
      </div>
    } @else {
      @if (loading) {
        <div class="skeleton-box flex flex-col gap-2">
          @for (item of [].constructor(10); track index; let index = $index) {
            <div class="skeleton-item flex flex-row gap-2 items-center">
              <div class="skeleton skeleton-checkbox"></div>
              <div class="skeleton skeleton-text"></div>
            </div>
          }
        </div>
      }
    }
  </div>
  @if (errorMessage !== undefined) {
    <small class="pt-1 error-message">{{ errorMessageText ?? '&nbsp;' }}</small>
  }
</div>
